<template>
  <div class="invite-record-page">
    <div class="header-box">
      <span class="recharge-date">邀请日期</span>
      <el-date-picker
        class="btn-date-picker"
        v-model="time"
        type="daterange"
        :editable="false"
        :clearable="false"
        align="center"
        range-separator="-"
        placeholder="选择日期范围"
      ></el-date-picker>
      <el-button @click="invitationalInfo">查询</el-button>
    </div>
    <div class="table-box">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :height="getStyle"
        :header-cell-style="{ background: '#f6f8fa' }"
      >
        <el-table-column
          prop="promotionDate"
          label="邀请时间"
          fixed
          align="center"
        >
        </el-table-column>
        <el-table-column prop="nickname" label="被邀请人" fixed align="center">
        </el-table-column>
        <el-table-column
          prop="headImageUrl"
          label="被邀请人头像"
          fixed
          align="center"
        >
          <template slot-scope="scope">
            <img :src="scope.row.headImageUrl" alt="" />
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="使用状态"
          fixed
          align="center"
        >
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="1"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { invitationalInfo } from '@/api/activity.js'
import { myMixin } from '@/mixins/mixin.js'
export default {
  name: 'InviteRecord',
  components: {},
  props: {},
  mixins: [myMixin],
  data() {
    return {
      time: [],
      params: {
        beginDate: '',
        endDate: '',
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      loading: false
    }
  },
  computed: {
    getStyle() {
      if (this.tableData.length > 0) {
        return '100%'
      } else {
        return 'auto'
      }
    }
  },
  watch: {},
  created() {
    this.time = [new Date(this.getStartDate()), new Date()]
    this.invitationalInfo()
  },
  methods: {
    invitationalInfo() {
      this.loading = true
      this.params.beginDate = this.format(this.time[0])
      this.params.endDate = this.format(this.time[1])
      invitationalInfo(this.params).then(res => {
        this.loading = false
        if (res.success) {
          this.tableData = res.data.data || []
          this.total = res.data.totalRecord
        }
      })
    },
    handleSizeChange(val) {
      this.params.pageSize = val
      this.invitationalInfo()
    },
    handleCurrentChange(val) {
      this.params.pageNo = val
      this.invitationalInfo()
    }
  }
}
</script>

<style scoped lang="scss">
.invite-record-page {
  position: relative;
  width: 1010px;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 2px 12px 0px #edecf6;
  border-radius: 4px;
  .header-box {
    display: flex;
    align-items: center;
    .recharge-date {
      margin-right: 22px;
    }
    .el-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 30px;
      background: linear-gradient(#3777ff, #0e39ff);
      border-color: #3777ff;
      color: #fff;
      margin: 0 0 0 22px;
    }
    ::v-deep.el-input__inner {
      width: 180px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }
    ::v-deep.el-icon-arrow-up {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ::v-deep.el-date-editor {
    width: 200px !important;
    height: 30px;
    line-height: 30px !important;
    font-size: 12px !important;

    .el-range-input {
      font-size: 12px;
    }

    .el-icon-date {
      margin-top: -8px;
      &::before {
        margin-right: -360px;
      }
    }

    .el-range__icon {
      margin-left: -22px;
    }

    .el-range-separator {
      line-height: 22px;
    }
  }
  .table-box {
    height: calc(100% - 100px);
    margin-top: 20px;
    img {
      width: 66px;
      height: 66px;
    }
    ::v-deep.el-table__header {
      .cell {
        color: #373e4f;
      }
    }
  }
  .block {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
